import MenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material'
import { ReactNode } from 'react'
import { IFormOptionEntry } from '@/models'

const StyledSelect = styled(MuiSelect)<MuiSelectProps>(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.border['200']}`,
  height: '38px',
  width: '100%',
  borderRadius: theme.decoration.border.radius.s,
  boxShadow: theme.decoration.shadow.default,
  '& .MuiSelect-select ': {
    padding: 0,
  },
  padding: '8px',
  fontSize: '14px',
}))

interface ISelectProps extends MuiSelectProps {
  error?: boolean
  onChange: (TBasicType) => void
  options?: IFormOptionEntry[]
}
export function Select(props: ISelectProps) {
  const { options, ...otherProps } = props

  return (
    <StyledSelect
      {...otherProps}
      displayEmpty
      renderValue={(selected) =>
        renderSelectedValue({ selected, placeholder: props.placeholder, options: props.options })
      }
      onChange={(evt) => {
        props.onChange(evt.target.value)
      }}
    >
      <MenuItem disabled value="">
        <em>{props.placeholder}</em>
      </MenuItem>
      {options?.map((entry) => (
        <MenuItem key={entry.value} value={entry.value}>
          {props.multiple && <Checkbox checked={isValueSelected({ values: props.value, entry })} />}
          {entry.label}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

const isValueSelected = ({ values, entry }) => {
  return values.includes(entry.value)
}

const renderSelectedValue = ({ selected, placeholder, options }) => {
  const isArray = Array.isArray(selected)
  if ((isArray && !selected.length) || !selected) return <div>{placeholder}</div>
  if (!isArray) return <div>{getLabel(selected as string | number, options) as ReactNode}</div>

  const labels = selected.map((option) => getLabel(option, options))
  return <div>{labels.join(', ')}</div>
}

function getLabel(value: string | number, options?: IFormOptionEntry[]) {
  if (!options) return value
  return options.find((opt) => opt.value === value)?.label || value
}
