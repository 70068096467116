import { useEffect } from 'react'
import { TBasicType, TPaymentService, IPaymentServiceConfiguration } from '@/models'
import { useRecoilState } from 'recoil'
import { paymentServiceState } from '@/state/paymentServiceDetail'
import { paymentServicesListState } from '@/state/paymentServicesList'
import { useAppConfig } from '@/hooks/useAppConfig'
import { EventConstants } from '@/constants'

export function usePaymentServiceSettings() {
  const [pServiceState, setPaymentServiceState] = useRecoilState(paymentServiceState)
  const { config } = useAppConfig()

  const PaymentServiceConfiguration = config.factories.paymentServiceConfigurations
  const EventBus = config.factories.eventBus

  const updatePaymentServiceSettings = async (
    paymentService: TPaymentService,
    payload: { [setting: string]: TBasicType | null },
  ) => {
    setPaymentServiceState({ ...pServiceState, error: null, updatingSetting: true })
    try {
      const result = await PaymentServiceConfiguration().updateSettings({
        provider: paymentService.provider,
        data: { extraContent: paymentService.extraContent, ...payload },
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })

      return { data: result }
    } catch (e) {
      setPaymentServiceState({ ...pServiceState, error: e, loading: false, updatingSetting: false })
      return { error: e }
    }
  }

  return {
    state: pServiceState,
    actions: {
      updatePaymentServiceSettings,
    },
  }
}

export function usePaymentService(provider?: string) {
  const [pServiceState, setPaymentServiceState] = useRecoilState(paymentServiceState)
  const [listState, setListState] = useRecoilState(paymentServicesListState)

  const { config } = useAppConfig()

  const PaymentServiceConfiguration = config.factories.paymentServiceConfigurations
  const PaymentServices = config.factories.paymentServices
  const EventBus = config.factories.eventBus

  const fetchPaymentService = async (provider: string) => {
    setPaymentServiceState({ ...pServiceState, error: null, loading: true })
    try {
      const paymentService = await PaymentServices().fetchPaymentService(provider)
      setPaymentServiceState({
        ...pServiceState,
        data: paymentService,
        error: null,
        loading: false,
      })
      return { data: paymentService }
    } catch (e) {
      setPaymentServiceState({ ...pServiceState, error: e, loading: false })
      return { error: e }
    }
  }

  const connect = async (
    paymentService: TPaymentService,
    paymentServiceConfig: IPaymentServiceConfiguration,
  ) => {
    setPaymentServiceState({ ...pServiceState, error: null, loading: true })

    try {
      const result = await PaymentServiceConfiguration().connect(paymentServiceConfig)
      const newData = { ...paymentService, ...result, connected: true } as TPaymentService

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: newData,
      })

      setPaymentServiceState({
        ...pServiceState,
        data: newData,
        error: null,
        loading: false,
      })
      setListState({
        ...listState,
        data: listState.data.map((paymentService) => {
          if (paymentService.provider !== pServiceState.data?.provider) return paymentService

          return { ...paymentService, ...result }
        }),
      })

      return { data: newData }
    } catch (e) {
      setPaymentServiceState({ ...pServiceState, error: e, loading: false })
      return { error: e }
    }
  }

  useEffect(() => {
    if (provider && pServiceState.data?.provider !== provider) {
      void fetchPaymentService(provider)
    }
  }, [provider])

  return {
    state: pServiceState,
    actions: {
      fetchPaymentService,
      connect,
    },
  }
}
