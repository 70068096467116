import { Input } from '@/components/Input'
import { NumericFormat } from 'react-number-format'

interface IPercentageInputProps {
  disabled?: boolean
  value?: number | null
  onChange: (value: number | null) => void
}

export function PercentInput(props: IPercentageInputProps) {
  return (
    <NumericFormat
      disabled={props.disabled}
      thousandSeparator={','}
      decimalSeparator={'.'}
      allowNegative={false}
      allowLeadingZeros={false}
      suffix={'%'}
      decimalScale={2}
      customInput={Input}
      onValueChange={(values) => {
        const floatValue = values.floatValue ?? null
        props.onChange(floatValue)
      }}
      value={typeof props.value === 'number' ? props.value : null}
    />
  )
}
