import { useRecoilState } from 'recoil'
import {
  paymentConfigurationApprovalsListState,
  paymentConfigurationApprovalState,
} from '@/state/paymentConfigurationApprovals'
import { useAppConfig } from '@/hooks/useAppConfig'
import { EPaymentConfigurationStatus, IPaymentServiceConfiguration } from '@/models'
import { EventConstants } from '@/constants'

export const useConfigurationApproval = (configuration: IPaymentServiceConfiguration) => {
  const [state, setState] = useRecoilState(paymentConfigurationApprovalState(configuration.id))
  const [listState, setListState] = useRecoilState(
    paymentConfigurationApprovalsListState(configuration.provider),
  )

  const { config } = useAppConfig()
  const PaymentServiceConfigurations = config.factories.paymentServiceConfigurations
  const EventBus = config.factories.eventBus

  const updateConfigurationApprovalStatus = async (newStatus: EPaymentConfigurationStatus) => {
    setState({ error: null, loading: true })
    try {
      const res: IPaymentServiceConfiguration =
        await PaymentServiceConfigurations().updateApprovalStatus(configuration, newStatus)

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: res,
      })

      setState({ error: null, loading: false })
      setListState({
        ...listState,
        data: listState.data.map((config) => (config.id === configuration.id ? res : config)),
      })
      return { data: res }
    } catch (e) {
      setState({ error: e, loading: false })
      return { error: e }
    }
  }

  return {
    actions: {
      updateConfigurationApprovalStatus,
    },
    state,
  }
}
