import { Button, CardActions, CardContent } from '@mui/material'
import { Divider } from '@/components/Divider'
import { LoadingButton } from '@mui/lab'
import { Fields } from '@/components/forms/Form'
import { makeStyles } from '@/theme'
import { TFormik } from '@/components/forms/Form/FormFields'
import { TFormFieldOrFormFieldList } from '@/helpers/formBuilder'
import { useMemo } from 'react'

interface IBaseFormProps<T> {
  hideOptionalFields?: boolean
  formikInstance: TFormik<T>
  onCancel: () => void
  formFields: TFormFieldOrFormFieldList[]
}

export function BaseForm<T>(props: IBaseFormProps<T>) {
  const { classes, cx } = useStyles()

  const fields = useMemo(() => {
    if (!props.hideOptionalFields) return props.formFields

    return withoutOptionalFields(props.formFields)
  }, [props.formFields, props.hideOptionalFields])

  return (
    <form onSubmit={props.formikInstance.handleSubmit}>
      <CardContent className={cx(classes.content)}>
        <Fields fields={fields} formik={props.formikInstance} />
      </CardContent>
      <Divider />
      <CardActions className={cx(classes.actions)}>
        <Button
          type={'button'}
          onClick={props.onCancel}
          disabled={props.formikInstance.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant={'contained'}
          type={'submit'}
          loading={props.formikInstance.isSubmitting}
        >
          Save
        </LoadingButton>
      </CardActions>
    </form>
  )
}

function withoutOptionalFields(formFields: TFormFieldOrFormFieldList[]) {
  return formFields.reduce((acc: TFormFieldOrFormFieldList[], fieldOrList) => {
    if (Array.isArray(fieldOrList)) {
      return [...acc, fieldOrList.filter((field) => field.required)]
    } else if (fieldOrList.required) {
      return [...acc, fieldOrList]
    } else {
      return acc
    }
  }, [])
}

const useStyles = makeStyles()(() => ({
  content: {
    padding: '24px',
  },
  actions: {
    height: '80px',
    padding: '24px',
    justifyContent: 'space-between',
  },
}))
