import { PaymentServices } from '@/services/paymentServices'
import { BrowserEventBus } from '@/services/eventBus'
import { PaymentServiceConfigurations } from '@/services/paymentServiceConfigurations'
import { PaymentOptions } from '@/services/paymentOptions'
import { IAppConfig } from '@/config/types'
import { AdminPaymentOptions } from '@/services/adminPaymentOptions'
import { DummyImageUpload } from '@/services/imageUpload/dummyImageUpload'
import merge from 'lodash/merge'
import { PaymentServiceTerminals } from '@/services/paymentServiceTerminals'
import { PaymentServiceFees } from '@/services/paymentServiceFees'
import { TAppEnv } from '@/models'

export class AppConfig implements IAppConfig {
  baseURL = process.env.API_URL || ''
  merchant = { id: '', name: '', companyName: '', email: '' }
  authToken = ''
  mode = ''
  platform = ''
  isMultiMerchant = false
  initialized = false

  router = {
    navigate: () => {
      throw new Error('Missing navigate function')
    },
  }

  services = {
    imageUploadService: null,
    terminalListService: null,
  }

  init({ merchantId, merchant, authToken, platform, mode, services, router, isMultiMerchant }) {
    this.mode = mode
    this.merchant = {
      id: merchant?.id || merchantId,
      name: merchant?.name || '',
      companyName: merchant?.companyName || '',
      email: merchant?.email || '',
    }
    this.authToken = authToken
    this.platform = platform
    this.router = router
    this.isMultiMerchant = isMultiMerchant

    if (services) {
      this.services = merge({}, this.services, services)
    }

    this.initialized = true
  }

  factories = {
    paymentServices(config: IAppConfig) {
      return new PaymentServices(config)
    },
    paymentServiceConfigurations(config: IAppConfig) {
      return new PaymentServiceConfigurations(config)
    },
    paymentOptions(config: IAppConfig) {
      return new PaymentOptions(config)
    },
    paymentServiceTerminals(config: IAppConfig) {
      return new PaymentServiceTerminals(config)
    },
    paymentServiceFees(config: IAppConfig) {
      return new PaymentServiceFees(config)
    },
    adminPaymentOptions(config: IAppConfig) {
      return new AdminPaymentOptions(config)
    },
    imageUpload(config: IAppConfig) {
      return config.services?.imageUploadService || new DummyImageUpload()
    },
    eventBus() {
      return new BrowserEventBus('blaze-pay')
    },
  }

  getEnvironment() {
    if (this.baseURL.includes('sandbox')) return TAppEnv.SANDBOX
    if (this.baseURL.includes('staging')) return TAppEnv.STAGING

    return TAppEnv.PRODUCTION
  }
}
