import * as yup from 'yup'
import { buildSchemaObject } from '@/helpers/formBuilder'
import { useFormik } from 'formik'
import { EFormFieldType, IAsyncRequestResult, IPaymentOption, IProvider, Provider } from '@/models'
import { useToast } from '@/hooks/useToast'
import { getErrorMessages, getFieldErrors } from '@/helpers/errors'
import { BaseForm } from '@/components/forms/Form'
import { trimFormValues } from '@/helpers/trimFormValues'
import { AppConstants } from '@/constants'
import { isNumber } from '@/helpers/isNumber'

interface IUpsertPaymentMethodFormProps {
  requiredFields: string[]
  paymentOption: IPaymentOption | null
  availablePaymentProvidersForCreation: IProvider[]
  onCancel: () => void
  onSuccess: () => void
  onSubmit: (values: Partial<IPaymentOption>) => Promise<IAsyncRequestResult<IPaymentOption>>
}

export function UpsertPaymentMethodForm(props: IUpsertPaymentMethodFormProps) {
  const toast = useToast()
  const { paymentOption } = props
  const fields = getFields({
    paymentOption,
    requiredFields: props.requiredFields,
    providers: props.availablePaymentProvidersForCreation,
  })
  const validationSchema = yup.object(buildSchemaObject(fields))
  const formik = useFormik<Partial<IPaymentOption>>({
    initialValues: {
      provider: paymentOption?.provider || '',
      about: paymentOption?.about || '',
      availableCountries: paymentOption?.availableCountries || undefined,
      posDisplayName: paymentOption?.posDisplayName || '',
      posAvailable: paymentOption?.posAvailable || false,
      ecomDisplayName: paymentOption?.ecomDisplayName || '',
      ecomAvailable: paymentOption?.ecomAvailable || false,
      description: paymentOption?.description || '',
      minimumChargeAmount: paymentOption?.minimumChargeAmount,
      maximumChargeAmount: paymentOption?.maximumChargeAmount,
      integrated: paymentOption ? paymentOption.integrated : true,
      increment: paymentOption?.increment,
      requireApproval: paymentOption?.requireApproval,
      allowTerminalTips: paymentOption ? paymentOption?.allowTerminalTips : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return props.onSubmit(trimFormValues(values)).then((result) => {
        if (result.error) {
          result.error.errors && formik.setErrors(getFieldErrors(result.error))

          const errorMessages = [
            'Failed to update payment option',
            ...getErrorMessages(result.error),
          ]

          toast.showMessage(errorMessages.join('\n'), 'error')
        } else {
          toast.showMessage('Payment option updated', 'success')
          props.onSuccess()
        }
      })
    },
  })

  return <BaseForm formikInstance={formik} onCancel={props.onCancel} formFields={fields} />
}

const getFields = ({ paymentOption, requiredFields, providers }) =>
  [
    {
      label: 'Merchant Processor',
      placeholder: 'Select merchant processor',
      type: EFormFieldType.OPTIONS,
      name: 'provider',
      disabled: !!paymentOption,
      required: true,
      schema: yup.string().required('Merchant processor is required'),
      options: providers.map((provider) => ({ value: provider.value, label: provider.value })),
      dimensions: { xs: 12, md: 6, lg: 3 },
    },
    {
      label: 'About',
      placeholder: 'Insert about text',
      type: EFormFieldType.RICH_TEXT,
      name: 'about',
      schema: yup.string(),
      dimensions: { xs: 12, md: 8, lg: 4 },
      minRows: 10,
    },
    {
      label: 'Available Regions',
      placeholder: 'Select available regions',
      type: EFormFieldType.OPTIONS,
      name: 'availableCountries',
      multiple: true,
      schema: yup
        .array()
        .of(yup.string())
        .test({
          message: 'Available regions is required',
          test: (arr) => arr && arr.length > 0,
        }),
      options: [
        {
          label: 'Canada',
          value: 'ca',
        },
        {
          label: 'United States',
          value: 'us',
        },
      ],
      dimensions: { xs: 12, md: 6, lg: 3 },
    },
    {
      label: 'POS Display Name',
      placeholder: 'Insert pos display name',
      type: EFormFieldType.STRING,
      name: 'posDisplayName',
      schema: yup
        .string()
        .max(
          AppConstants.PAYMENT_DISPLAY_NAME_LIMIT,
          `Must have ${AppConstants.PAYMENT_DISPLAY_NAME_LIMIT} characters or less`,
        ),
      dimensions: { xs: 12, md: 6, lg: 3 },
    },
    {
      label: 'POS Available',
      type: EFormFieldType.BOOLEAN,
      name: 'posAvailable',
      schema: yup.boolean(),
    },
    {
      label: 'ECOM Display Name',
      placeholder: 'Insert pos display name',
      type: EFormFieldType.STRING,
      name: 'ecomDisplayName',
      schema: yup
        .string()
        .max(
          AppConstants.PAYMENT_DISPLAY_NAME_LIMIT,
          `Must have ${AppConstants.PAYMENT_DISPLAY_NAME_LIMIT} characters or less`,
        ),
      dimensions: { xs: 12, md: 6, lg: 3 },
    },
    {
      label: 'ECOM Available',
      type: EFormFieldType.BOOLEAN,
      name: 'ecomAvailable',
      schema: yup.boolean(),
    },
    {
      label: 'Description',
      placeholder: 'Add description',
      type: EFormFieldType.BIG_STRING,
      name: 'description',
      minRows: 10,
      schema: yup.string(),
      dimensions: { xs: 12, md: 8, lg: 4 },
    },
    [
      {
        label: 'Minimum charge amount',
        placeholder: 'Add minimum charge amount',
        type: EFormFieldType.CURRENCY,
        name: 'minimumChargeAmount',
        schema: yup
          .number()
          .default(null)
          .min(0, 'Value must be greater of equal to 0')
          .test({
            message: 'Minimum charge amount must be smaller than the maximum charge amount',
            test: function (value?: number | null) {
              return (
                !isNumber(value) ||
                !isNumber(this.parent.maximumChargeAmount) ||
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value! < this.parent.maximumChargeAmount // we are sure that value is a number here
              )
            },
          })
          .test({
            message: 'Must be an integer',
            test: function (value) {
              return !isNumber(value) || Number.isInteger(value)
            },
          })
          .notRequired(),
        dimensions: { xs: 12, md: 3, lg: 3 },
      },
      {
        label: 'Maximum charge amount',
        placeholder: 'Add maximum charge amount',
        type: ({ formik }) =>
          determineFieldType(formik?.values?.provider, [Provider.PAYAUTH], EFormFieldType.CURRENCY),
        name: 'maximumChargeAmount',
        schema: yup
          .number()
          .default(null)
          .positive('Must be a positive number')
          .test({
            message: 'Maximum charge amount must be greater than the minimum charge amount',
            test: function (value) {
              return (
                !isNumber(value) ||
                !isNumber(this.parent.minimumChargeAmount) ||
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value! > this.parent.minimumChargeAmount // we are sure that value is a number here
              )
            },
          })
          .test({
            message: 'Must be an integer',
            test: function (value) {
              return !isNumber(value) || Number.isInteger(value)
            },
          })
          .notRequired(),
        dimensions: { xs: 12, md: 3, lg: 3 },
      },
    ],
    [
      {
        label: 'Increment',
        placeholder: 'Add increment',
        type: EFormFieldType.CURRENCY,
        name: 'increment',
        schema: yup
          .number()
          .default(null)
          .positive('Must be a positive number')
          .test({
            message: 'Must be an integer',
            test: function (value) {
              return !isNumber(value) || Number.isInteger(value)
            },
          })
          .notRequired(),
        dimensions: { xs: 12, md: 3, lg: 3 },
      },
      {
        label: 'Integrated',
        placeholder: 'integrated',
        type: EFormFieldType.BOOLEAN,
        name: 'integrated',
        schema: yup.boolean().default(true),
        dimensions: { xs: 12, md: 1, lg: 1 },
      },
      {
        label: 'Requires Admin Approval',
        type: EFormFieldType.BOOLEAN,
        name: 'requireApproval',
        schema: yup.boolean(),
        dimensions: { xs: 12, md: 2, lg: 2 },
      },
    ],
    [
      {
        label: 'Allow Terminal Tips',
        type: EFormFieldType.BOOLEAN,
        name: 'allowTerminalTips',
        schema: yup.boolean().default(true),
        initialValue: true,
        dimensions: { xs: 12, md: 2, lg: 2 },
      },
    ],
    [
      {
        label: 'Logo Image',
        placeholder: 'Upload logo image',
        type: EFormFieldType.FILE_UPLOAD,
        name: 'imageFile',
        initialValue: paymentOption?.imageUrl || '',
        schema: yup.mixed(),
        dimensions: { xs: 12, md: 3, lg: 3 },
      },
      {
        label: 'Icon Image',
        placeholder: 'Upload icon image',
        type: EFormFieldType.FILE_UPLOAD,
        name: 'iconFile',
        initialValue: paymentOption?.iconUrl || '',
        schema: yup.mixed(),
        dimensions: { xs: 12, md: 3, lg: 3 },
      },
    ],
  ].map((fieldOrArray) => {
    const newField = applyRequiredValidation(fieldOrArray, requiredFields)

    return Array.isArray(newField) ? newField : [newField] // The [newField] makes each field to be on a single line
  })

const applyRequiredValidation = (fieldOrArray, requiredFields) => {
  if (Array.isArray(fieldOrArray))
    return fieldOrArray.map((field) => applyRequiredValidation(field, requiredFields))

  const field = fieldOrArray
  const isRequired = isFieldRequired(requiredFields, field.name)
  if (isRequired) {
    const fieldName = field.label.charAt(0).toUpperCase() + field.label.slice(1).toLowerCase()
    return {
      ...field,
      required: true,
      schema: field.schema.required(`${fieldName} is required`),
    }
  } else {
    return field
  }
}

const isFieldRequired = (requiredFields: string[], fieldName: string) => {
  return requiredFields.includes(fieldName)
}

const determineFieldType = (
  provider: string,
  hiddenProviders: Provider[],
  defaultType: EFormFieldType,
): EFormFieldType => {
  return hiddenProviders.includes(provider as Provider) ? EFormFieldType.HIDDEN : defaultType
}
