import { useState } from 'react'
import { EPaymentConfigurationStatus, IPaymentServiceConfiguration } from '@/models'
import { useConfigurationApproval } from '@/hooks/useConfigurationApproval'
import { ListItem, ListItemText, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { PaymentServiceApprovalStatusBadge } from '@/components/PaymentServiceApprovalStatusBadge'
import { ConfirmationDialog } from '@/components/ConfirmationDialog'
import { makeStyles } from '@/theme'

interface IConfigurationApprovalRowProps {
  config: IPaymentServiceConfiguration
}

export function ConfigurationApprovalRow(props: IConfigurationApprovalRowProps) {
  const [pendingAction, setPendingAction] = useState<EPaymentConfigurationStatus | null>(null)

  function closeConfirmation() {
    setPendingAction(null)
  }

  async function confirmAction() {
    if (pendingAction) await updateConfigurationApprovalStatus(pendingAction)
    closeConfirmation()
  }

  const {
    actions: { updateConfigurationApprovalStatus },
    state: { loading },
  } = useConfigurationApproval(props.config)

  const { cx, classes } = useStyles()

  const handleChange = (event, newStatus: EPaymentConfigurationStatus | null) => {
    setPendingAction(newStatus ?? EPaymentConfigurationStatus.PENDING_APPROVAL)
  }
  const control = {
    value: props.config.status,
    onChange: handleChange,
    exclusive: true,
  }

  return (
    <ListItem
      className={cx(classes.listItem)}
      secondaryAction={
        <div>
          <ToggleButtonGroup size="small" {...control}>
            <ToggleButton sx={{ columnGap: '4px' }} value={EPaymentConfigurationStatus.APPROVED}>
              <PaymentServiceApprovalStatusBadge status={EPaymentConfigurationStatus.APPROVED} />
              Approved
            </ToggleButton>
            <ToggleButton sx={{ columnGap: '4px' }} value={EPaymentConfigurationStatus.DENIED}>
              <PaymentServiceApprovalStatusBadge status={EPaymentConfigurationStatus.DENIED} />
              Denied
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      }
    >
      <ListItemText primary={<ConfigurationApprovalRowText config={props.config} />} />
      {pendingAction && (
        <ConfirmationDialog
          {...getConfirmationDialogInfo(pendingAction)}
          open={true}
          loading={loading}
          onClose={closeConfirmation}
          onCancel={closeConfirmation}
          onConfirm={confirmAction}
        />
      )}
    </ListItem>
  )
}

interface IConfigurationApprovalRowTextProps {
  config: IPaymentServiceConfiguration
}
function ConfigurationApprovalRowText(props: IConfigurationApprovalRowTextProps) {
  const { cx, classes } = useStyles()

  return (
    <div className={cx(classes.listItemText)}>
      <Typography variant="subtitle1" color="text.primary">
        {props.config.merchantName || '-'}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" className={cx(classes.merchantIdText)}>
        <small>{props.config.merchantId}</small>
      </Typography>
    </div>
  )
}

function getConfirmationDialogInfo(newStatus: EPaymentConfigurationStatus | null) {
  switch (newStatus) {
    case EPaymentConfigurationStatus.DENIED:
      return {
        title: `Configuration Denial`,
        text: 'Are you sure you want to deny this configuration?',
      }
    case EPaymentConfigurationStatus.APPROVED:
      return {
        title: `Configuration Approval`,
        text: 'Are you sure you want to approve this configuration?',
      }
    case EPaymentConfigurationStatus.PENDING_APPROVAL:
    default:
      return {
        title: `Configuration Pending Approval`,
        text: `Are you sure you want to set this configuration to pending approval?`,
      }
  }
}

const useStyles = makeStyles()(() => ({
  listItem: {
    padding: '4px',
    marginBottom: '16px',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 250px)',
  },
  merchantIdText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
