import { makeStyles } from '@/theme'
import { ConfigurationEntry } from '@/pages/PaymentServiceSettingsPage/ConfigurationEntry'
import { TPaymentService } from '@/models'
import get from 'lodash/get'

interface IConfigurationSectionProps {
  paymentService: TPaymentService
}

export const ConfigurationSection = (props: IConfigurationSectionProps) => {
  const { classes, cx } = useStyles()

  const { paymentService } = props
  const fieldEntries = paymentService.fields
    .filter((field) => get(paymentService, field.name)?.toString() && !field.writeOnly)
    .map((field) => ({
      name: field.name,
      label: field.label,
      value: get(paymentService, field.name),
    }))

  return (
    <div className={cx(classes.container)}>
      {fieldEntries.map((config, index) => (
        <ConfigurationEntry key={index} {...config} paymentService={paymentService} />
      ))}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '0',
    display: 'flex',
    rowGap: '16px',
    columnGap: '32px',
    flexWrap: 'wrap',
  },
}))
